(<template>
  <section class="empty-chat">
    <div class="empty-chat__title">{{ $gettext('Thank you for booking!') }}</div>
    <div class="empty-chat__title">{{ $gettext('Booking process') }}</div>
    <div class="empty-chat__processes-wrapper">
      <div class="empty-chat__processes">
        <div v-for="(process, index) in bookingProcesses"
             :key="index"
             :class="process.class"
             class="empty-chat__process">
          <span class="empty-chat__process-line"></span>
          <span class="empty-chat__process-text"
                v-html="process.text"></span>
        </div>
      </div>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      bookingProcessList: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      bookingProcesses() {
        if (this.bookingProcessList && this.bookingProcessList.length) {
          return this.bookingProcessList;
        } else {
          return [{
            class: 'empty-chat__process--left',
            text: this.$gettext('Booking<br/>registered')
          }, {
            class: 'empty-chat__process--right',
            text: this.$gettext('Interpreters<br/>get invited')
          }, {
            class: 'empty-chat__process--right',
            text: this.$gettext('Interpreters<br/>apply')
          }, {
            class: 'empty-chat__process--right',
            text: this.$gettext('Interpreters<br/>get awarded')
          }, {
            class: 'empty-chat__process--left',
            text: this.$gettext('Receive confirmation<br/>e-mail from the platform')
          }, {
            class: 'empty-chat__process--center',
            text: this.$gettext('Assignment starts')
          }];
        }
      }
    }
  };
</script>

<style scoped>
  .empty-chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    color: #646d8c;
    font-size: 10px;
  }

  .empty-chat__title {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
  }

  .empty-chat__steps {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .empty-chat__processes-wrapper {
    width: 100%;
    height: 100%;
  }

  .empty-chat__processes {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }

  .empty-chat__process {
    position: relative;
    display: flex;
    width: 100%;
  }

  .empty-chat__process::before {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    width: 50%;
    height: 1px;
    background-color: #a7abbd;
    transform: translateY(-50%);
  }

  .empty-chat__process--left::before {
    left: 0;
  }

  .empty-chat__process--right::before {
    right: 0;
  }

  .empty-chat__process--center::before {
    content: none;
  }

  .empty-chat__process::after {
    content: '';
    position: absolute;
    left: 50%;
    z-index: 1;
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: #646d8c;
  }

  .empty-chat__process--left::after,
  .empty-chat__process--right::after {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .empty-chat__process--center::after {
    top: 0;
    transform: translateX(-50%);
  }

  .empty-chat__process--left .empty-chat__process-text {
    margin-right: auto;
  }

  .empty-chat__process--right .empty-chat__process-text {
    margin-left: auto;
  }

  .empty-chat__process--left + .empty-chat__process--left {
    margin-top: 10px;
  }

  .empty-chat__process--right + .empty-chat__process--right {
    margin-top: 10px;
  }

  .empty-chat__process--left + .empty-chat__process--right {
    margin-top: -10px;
  }

  .empty-chat__process--right + .empty-chat__process--left {
    margin-top: -10px;
  }

  .empty-chat__process--center .empty-chat__process-text {
    margin: 10px auto 0;
  }

  .empty-chat__process-text {
    z-index: 1;
    padding: 0 5px;
    background-color: #e9eaef;
  }

  .empty-chat__process-line {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 1px;
    height: calc(100% + 10px);
    background-color: #a7abbd;
    transform: translateX(-50%);
  }
</style>
