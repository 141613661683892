(<template>
  <div class="no-chat-info">
    <no-chat-invite v-if="isInvitedJob && !isDirectJob" />
    <div v-if="isDirectInvitedJob"
         class="no-chat-info__event no-chat-info__direct-invite">
      <p>{{ $gettext('The platform reserves the right to invite other interpreters if the requested interpreter is not available.') }}</p>
    </div>
    <div v-if="isAwardedJob && !isVideoJob && !isVideoroomJob"
         class="no-chat-info__event no-chat-info__awarded">
      <p>{{ $gettext('Please remember to check this page for changes, in case of a last minute update.') }}</p>
      <p>{{ $gettext('If we change the interpreter for logistical reasons, we will do our best to inform you.') }}</p>
    </div>
    <div v-if="isNeedsFeedbackJob && showFeedbackInfoBlock"
         class="no-chat-info__event no-chat-info__feedback">
      <h3 class="no-chat-info__title">{{ $gettext('Thank you for booking!') }}</h3>
      <p>{{ $gettext('Now that the booking is finished:') }}</p>
      <p>{{ $gettext('Please give us your feedback above') }}</p>
      <p>{{ $gettext('Add the interpreter as a favorite if you wish to') }}</p>
      <p v-html="viewInterpretersText"></p>
      <p v-html="makeBookingText"></p>
    </div>
    <div v-if="isCancelledJob"
         class="no-chat-info__event no-chat-info__cancelled">
      <router-link :to="$makeRoute({name: 'BuyerPostInterpretation'})"
                   class="sk-btn sk-btn--default no-chat-info__btn">{{ $gettext('New assignment') }}</router-link>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import EmptyChatInvite from '@/components/assignment_components/one_assignment/no_chat_info/EmptyChatInvite';

  export default {
    components: {
      'no-chat-invite': EmptyChatInvite
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: (state) => state.storeJobObj.interpretationRequirement || {},
        assignmentObj: (state) => state.storeJobObj || {},
        suitableInterpreters: (state) => state.suitableInterpreters || [],
        jobStatus: (state) => {
          return state.storeJobObj && state.storeJobObj.status ? state.storeJobObj.status : '';
        }
      }),
      viewInterpretersText() {
        const resolvedHref = this.$router.resolve(this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment().format('YYYY-MM-DD'),
            langFrom: '3'
          }
        })).href;
        const template = this.$gettext('Click <a class="no-chat-info__link" href="%{ n }">here</a> to view all our interpreters');
        return this.$gettextInterpolate(template, {n: resolvedHref});
      },
      makeBookingText() {
        const resolvedHref = this.$router.resolve(this.$makeRoute({name: 'BuyerSelectAssignmentType'})).href;
        const template = this.$gettext('Click <a class="no-chat-info__link" href="%{ n }">here</a> to make a new booking');
        return this.$gettextInterpolate(template, {n: resolvedHref});
      },
      finishTime1HourPassed() {
        return this.$moment().isAfter(this.$moment(this.assignmentObj.interpretationRequirement.finishTime).add(59, 'minutes'));
      },
      isVideoJob() { return this.assignmentRequirements.sessionType == 'video'; },
      isVideoroomJob() { return this.assignmentRequirements.sessionType == 'videoroom'; },
      isDirectJob() {
        return this.assignmentObj.direct || '';
      },
      finishTimePassed() {
        return this.$moment().isAfter(this.assignmentRequirements.finishTime);
      },
      showFeedbackInfoBlock() {
        return this.isVideoJob || this.isVideoroomJob ? this.finishTime1HourPassed : true;
      },
      isNeedsFeedbackJob() { return this.jobStatus == 'needsFeedback'; },
      isCancelledJob() { return this.jobStatus == 'cancelled'; },
      isFinishedJob() { return this.jobStatus == 'finished'; },
      isClosedJob() { return this.jobStatus == 'closed'; },
      isAwardedJob() {
        return this.jobStatus == 'accepted' || this.jobStatus == 'in_progress';
      },
      isDirectInvitedJob() {
        return this.suitableInterpreters.length >= 2
          && this.isInvitedJob
          && this.isDirectJob;
      },
      isInvitedJob() {
        return !this.isAwardedJob
          && !this.finishTimePassed
          && !this.isNeedsFeedbackJob
          && !this.isCancelledJob
          && !this.isFinishedJob
          && !this.isClosedJob;
      }
    }
  };
</script>

<style>
  .no-chat-info .conference-system-data {
    padding-right: 0;
    color: #fff;
  }

  .no-chat-info .conference-system-data__title {
    margin-bottom: 30px;
    font-weight: bold;
  }

  .no-chat-info .conference-system-data__title,
  .no-chat-info .conference-system-data__data-title {
    color: #fff;
  }

  .no-chat-info .no-chat-info__link {
    text-decoration: underline;
  }

  .no-chat-info .no-chat-info__link:hover {
    opacity: 0.5;
  }
</style>

<style scoped>
  .no-chat-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    color: #646d8c;
  }

  .no-chat-info__title {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
  }

  .no-chat-info__event {
    margin: auto;
    padding: 20px 30px;
  }

  .no-chat-info__feedback,
  .no-chat-info__cancelled {
    text-align: center;
  }

  .no-chat-info__btn {
    display: inline-block;
    width: auto;
    padding: 0 40px;
  }

  @media (max-width: 767px) {
    .no-chat-info__event {
      padding: 15px;
    }
  }
</style>
